













import { Component, Vue, Prop } from 'nuxt-property-decorator';

import { NavigationLinkProps } from '@/composables/page-navigation';

@Component
export default class SubPageNavigation extends Vue {
  @Prop({ type: Array, required: true })
  pages!: NavigationLinkProps[];
}
