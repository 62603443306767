












import { Component, mixins } from 'nuxt-property-decorator';

import { useSubNavigation } from '@/composables/page-navigation';
import { PageContentMixin } from '@/composables/page-content';

import { StandardContentDataFragment } from '@/graphql/generated';
import { ComposableMixin } from '@/mixins/composable';

@Component
export default class BiljetterPage extends mixins(
  PageContentMixin<StandardContentDataFragment>(),
  ComposableMixin(useSubNavigation),
) {
  get page () {
    return {
      title: this.pageContent.title,
      lead: this.pageContent.lead,
    };
  }
}
