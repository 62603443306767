







import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class DefaultPageHeader extends Vue {
  @Prop({ type: String })
  title!: string;

  @Prop(String)
  lead!: string;

  @Prop(Array)
  pages!: any[];
}
