







import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'LazyMount',
  props: {
    delay: {
      type: Number,
      default: 0,
    },
  },
});
